import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, Typography } from "@mui/material";

export const AboutUs = () => {
  return (
    <>
      <Header />
      <Container>
        <br />
        <br />
        <Typography variant="h3" textAlign="center">
          <b>About Us</b>
        </Typography>
        <br />
        <br />
        <Typography>
          Broadcast on Tv Channel & Live Stream on Facebook, Youtube, Website,
          Mobile App, Tata Sky Binge, Shemaroo Me, Amazon Fire Tv Stick, Roku
          Tv, Mx Player. Any types of religious Program, Katha Bhagwat ,Utasav,
          Sai Sandhya,Vishal Bhagwati jagran, Pooja , Pad Yatra , sheelanyas,
          Mandir Samaroh, intervews . <br /><br /> We have started our Devotional channel,
          Live, D-live, Facebook, Broadcast, Mobile App, YouTube & also coming
          soon in Regional channels. <br /><br /> We are producing Tv serial, Albums, Films &
          Telecast any type of Hindi songs, Tv Serial, Movies, Short Movie,
          Documentary Films, Talent show, Beauty Contest Personal Interview,
          Religious Programme I.e. Katha Bhagwat, Sai Sandhya, Mata ka Jagran
          etc.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};
