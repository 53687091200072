import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Typography, Container } from "@mui/material";

export const Contact = () => {
  return (
    <>
      <Header />
      <br />
      <br />
      <Container>
        <Typography variant="h3" textAlign="center">
          <b>Contact Us</b>
        </Typography>
        <br />
        <br />
        <Typography>
          <b>Email Account</b>
        </Typography>
        <br />
        <Typography>sevenwonderstv@gmail.com</Typography>
        <br />
        <br />
        <Typography>
          <b>Phone</b>
        </Typography>
        <br />
        <Typography>077669 24680</Typography>
      </Container>
      <Footer />
    </>
  );
};
