import { Navigation } from "swiper";
import { useEffect, useState } from "react";
import data from "../../data/ImageData.json";
import playerData from "../../data/allData.json";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useParams, Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Container, Typography } from "@mui/material";

export const Player = () => {
  const { videoId } = useParams();
  const [obj, setObj] = useState({});

  const getData = () => {
    playerData.filter((e) => e.id === videoId).map((e) => setObj(e));
  };

  useEffect(() => {
    getData();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [videoId]);

  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <iframe
          src={`https://www.youtube.com/embed/${obj.embedCode}`}
          frameborder="0"
        ></iframe>
      </Container>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h3">
          <b>{obj.name}</b>
        </Typography>
        <br />
        <br />
        <Typography>
          Broadcast on Tv Channel & Live Stream on Facebook, Youtube, Website,
          Mobile App, Tata Sky Binge, Shemaroo Me, Amazon Fire Tv Stick, Roku
          Tv, Mx Player. Any types of religious Program, Katha Bhagwat ,Utasav,
          Sai Sandhya,Vishal Bhagwati jagran, Pooja , Pad Yatra , sheelanyas,
          Mandir Samaroh, intervews . <br />
          <br /> We have started our Devotional channel, Live, D-live, Facebook,
          Broadcast, Mobile App, YouTube & also coming soon in Regional
          channels. <br />
          <br /> We are producing Tv serial, Albums, Films & Telecast any type
          of Hindi songs, Tv Serial, Movies, Short Movie, Documentary Films,
          Talent show, Beauty Contest Personal Interview, Religious Programme
          I.e. Katha Bhagwat, Sai Sandhya, Mata ka Jagran etc.
        </Typography>
      </Container>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 10 }}>
        <Typography variant="h5">Recommended Shows</Typography>
        <br />
        <Swiper modules={[Navigation]} slidesPerView={4} navigation>
          {data.cards.map((e, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={`/player/${e.id}`}>
                  <Box sx={{ p: 1 }}>
                    <img src={e.image} alt="Card" />
                  </Box>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
      <Footer />
    </>
  );
};
